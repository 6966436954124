<template>
  <router-link tag="div" :to="to" replace>
    <button
      :class="[
        'rounded-button border-neutral-light nav-button font-weight-300 subtitle-text text-neutral d-flex align-items-center justify-content-center',
      ]"
    >
      <v-icon v-if="type === 'back'" color="#fff" size="20px" class="me-1">
        mdi-chevron-left
      </v-icon>
      {{ type.toUpperCase() }}
      <v-icon v-if="type === 'next'" color="#fff" size="20px" class="ms-1">
        mdi-chevron-right
      </v-icon>
    </button>
  </router-link>
</template>

<script>
export default {
  name: "NavigationButton",
  props: {
    type: String,
    to: String,
  },
};
</script>

<style lang="scss" scoped>
.nav-button {
  width: 120px;
  height: 40px;
  border-radius: 40px;
}
</style>
