<template>
  <main class="container-fluid row m-0 p-0 no-gutters">
    <section
      class="d-flex flex-column justify-content-start m-0 py-0 px-0 col position-relative"
    >
      <video
        autoplay
        loop
        muted
        style="
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: 0;
          object-fit: cover;
        "
        :src="require('../assets/bg-loop.mp4')"
      />
      <div
        class="p-3 z-200 navigation-border-bottom d-flex flex-column flex-md-row align-items-start align-items-md-center position-sticky top-0 bg-neutral-dark"
      >
        <div class="d-flex align-items-center">
          <span class="text-neutral-light strong-header-text mr-2">
            {{ $store.state.project.name || "Create New Project" }}
          </span>
          <v-icon color="#fff" size="30px"> mdi-chevron-right </v-icon>
        </div>

        <span class="strong-header-text font-weight-400 text-primary-light">
          {{ $route.meta.title || "" }}
        </span>
      </div>
      <transition mode="out-in" :name="`slide-${transitionName}`">
        <router-view @showWelcomeModal="showModal = true" style="z-index: 1" />
      </transition>
      <section
        class="bg-neutral-dark mt-auto p-3 navigation-border-top position-sticky bottom-0 d-flex justify-content-between w-100"
        style="z-index: 1"
        v-if="!$route.meta.hideNavigation"
      >
        <navigation-button
          :class="{ invisible: $route.meta.prev === '' }"
          type="back"
          @click.native="backPressed"
          :to="''"
        />
        <navigation-button
          v-if="$route.meta.next"
          type="next"
          :to="''"
          @click.native="nextPressed"
        />
      </section>
    </section>
    <section
      class="z-200"
      :class="['m-0 p-0 col-3 d-none d-md-block', mini ? 'mini' : '']"
    >
      <img
        :src="require('/src/assets/images/tech-bg.png')"
        alt="tech-bg"
        class="img-height"
      />
    </section>
    <transition name="fade" appear>
      <modal
        v-if="showModal"
        :show="showModal"
        @close="showModal = false"
        :hide-close="true"
      >
        <div
          class="ht-80p wd-80p bg-neutral-dark d-flex flex-column align-items-center justify-content-around p-3"
          style="
            border-radius: 1.5rem;
            border: 2px solid var(--primary);
            overflow-y: auto;
          "
        >
          <img
            class="wd-100p wd-md-40p mb-3 mt-3"
            :src="require('../assets/images/kids.png')"
          />
          <div
            class="text-muted-light body-text font-weight-300 wd-70p text-center"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            dictum vitae est eu semper. Interdum et malesuada fames ac ante
            ipsum primis in faucibus. Proin egestas felis commodo arcu blandit
            posuere. Vivamus consequat dolor egestas cursus faucibus.
          </div>

          <button
            class="rounded-button border-primary text-neutral-light subtitle-text font-weight-500"
            @click="showModal = false"
          >
            OK
          </button>
        </div>
      </modal>
    </transition>
  </main>
</template>

<script>
import NavigationButton from "../components/NavigationButton.vue";
import Modal from "@/components/Modal";

export default {
  name: "CreateStore",
  components: {
    Modal,
    NavigationButton,
  },
  computed: {
    mini: function () {
      return this.$route.meta?.mini;
    },
  },
  data() {
    return {
      showModal: false,
      transitionName: "left",
    };
  },
  methods: {
    backPressed() {
      this.transitionName = "right";
      console.log("hey");

      this.$router.push({ path: this.$route.meta.prev || "" });
    },
    nextPressed() {
      this.transitionName = "left";

      this.$router.push({ path: this.$route.meta.next || "" });
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-right-enter-from {
  transform: translateX(-100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}

.responsive-image {
  overflow: hidden;
  max-width: 300px;
  @media (max-width: 1100px) {
    display: none;
  }
}

.img-height {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  object-fit: cover;
  animation: backgroundScroll ease-in-out 35s infinite;
}
.mini {
  max-width: 20%;
}

@keyframes backgroundScroll {
  0% {
    object-position: 0;
  }
  50% {
    object-position: 100%;
  }

  100% {
    object-position: 0;
  }
}

.navigation-border-top {
  border-top: 1px solid var(--grey);
}

.navigation-border-bottom {
  border-bottom: 1px solid var(--grey);
}
</style>
